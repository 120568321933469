import type {
  InputTypeTextProps,
  SchemaOptions,
  ValidationFunc,
} from "~/components/Forms/Fields/Base/InputTypesProps";
import { InputTypeText } from "~/components/Forms/Fields/Base/InputTypeText";
import { createSignal, splitProps } from "solid-js";
import { check, safeParse, string, pipe } from "valibot";
import ValidationErrors from "./Base/ValidationErrors";

const LABEL = "Mon budget";
const HELP = undefined;
const IS_REQUIRED_BY_DEFAULT = false;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function AccessBudgetSchema(options?: SchemaOptions) {
  return {
    monthly_income: pipe(
      string("Veuillez saisir un montant en Euros"),
      check(
        (input) =>
          /^\d+$/.test(input) || /^[-+]\d+$/.test(input) || input === "",
        "Veuillez saisir un montant en Euros",
      ),
    ),
  };
}

export function AccessBudget(props: InputTypeTextProps) {
  const [local, others] = splitProps(props, ["label", "help", "required"]);
  const [validationErrors, setValidationErrors] = createSignal<string[]>([]);

  const validate: ValidationFunc = (input) => {
    const ret = safeParse(AccessBudgetSchema().monthly_income, input);
    ret.success
      ? setValidationErrors([])
      : setValidationErrors(ret.issues.map((issue) => issue.message));
  };

  return (
    <>
      <div class={`form-item-${props.name}`}>
        <InputTypeText
          label={local.label || LABEL}
          type="number"
          inputmode="numeric"
          help={local.help || HELP}
          validateFn={validate}
          aria-invalid={validationErrors().length > 0}
          aria-errormessage={`err-${props.name}`}
          required={
            local.required !== undefined
              ? local.required
              : IS_REQUIRED_BY_DEFAULT
          }
          appendText="€ / mois"
          {...others}
        />
        <ValidationErrors errors={validationErrors()} field={props.name} />
      </div>
    </>
  );
}
