import { Portal } from "solid-js/web";

import "./SeoText.css";

import IconApartment from "~/img/icons/apartment_48dp.svg";
import IconSavings from "~/img/icons/savings_48dp.svg";
import IconEuro from "~/img/icons/euro_48dp.svg";
import IconAgent from "~/img/icons/real_estate_agent_48dp.svg";

export default function SeoText() {
  return (
    <>
      <div class="content-part block-seo" data-test="block-seo">
        <section class="avantages" id="avantages">
          <h2>
            Les avantages{" "}
            <img
              src="/images/nouveau-neuf/logo-access.svg"
              alt="Access"
              height="34"
              width="162"
            />
          </h2>
          <div class="cards" data-test="cards">
            <div class="card" data-test="card">
              <i aria-hidden="true" class="cog-icon">
                <IconApartment />
              </i>
              <div class="content">
                <p class="inner">
                  Des mensualités
                  <br />
                  proches d’un
                  <strong>loyer</strong>
                </p>
                <p class="hover">
                  Grâce à une solution de financement sur mesure, vos futures
                  mensualités sont compatibles avec votre budget, devenir
                  propriétaire ne grève pas votre pouvoir d'achat.
                </p>
              </div>
            </div>
            <div class="card" data-test="card">
              <i aria-hidden="true" class="cog-icon">
                <IconSavings />
              </i>
              <div class="content">
                <p class="inner">
                  Même
                  <strong>
                    sans apport<sup>(1)</sup>
                  </strong>
                </p>
                <p class="hover">
                  Nous prenons en charge les frais de notaire, les frais de
                  dossiers et même les intérêts intercalaires.
                </p>
              </div>
            </div>
            <div class="card" data-test="card">
              <i aria-hidden="true" class="cog-icon">
                <IconEuro />
              </i>
              <div class="content">
                <p class="inner">
                  Seulement
                  <strong>500 €</strong>{" "}
                  <span>
                    à la réservation<sup>(1)</sup>
                  </span>
                </p>
                <p class="hover">
                  Vous ne payez que 500&nbsp;€ (au lieu des 2&nbsp;% ou 5&nbsp;%
                  du prix acquisition réglementaires) à la réservation et puis
                  plus rien jusqu’à la remise des clefs de votre appartement.
                </p>
              </div>
            </div>
            <div class="card" data-test="card">
              <i aria-hidden="true" class="cog-icon">
                <IconAgent />
              </i>
              <div class="content">
                <p class="inner">
                  <strong>0 frais</strong>{" "}
                  <span>
                    avant d’emménager<sup>(1)</sup>
                  </span>
                </p>
                <p class="hover">
                  Vous ne commencez à rembourser votre crédit qu’à votre
                  emménagement.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Portal mount={document.getElementById("legals-offer-receiver")!}>
        <p class="legals">
          (1) Exemple de mensualités pour une acquisition, en résidence
          principale, du Lot 10025, appartement 2 pièces alcôve situé 2ème étage
          de la résidence Rive Nature à Villeneuve-la-Garenne, d’une surface
          habitable de 49,59 m² avec un balcon de 9,48 m² et 1 place de
          stationnement. Appartement vendu en l’état futur d’achèvement en TVA
          réduite à 5,5 % au prix de 229 500,00€. Financement sans apport
          personnel réalisé grâce au PTZ, un PAS et à des prêts consentis auprès
          des partenaires bancaires du groupe ALTAREA par l’intermédiaire
          d’Altarea Solution Financement. TVA réduite à 5,5 % (acquisition pour
          10 ans minimum), PTZ et PAS, réservés aux primo-accédants pour l’achat
          d’un logement en résidence principale sont soumis à conditions de
          revenus. Montant total des crédits : 229 500 €. Durée totale des
          crédits : 25 ans. TAEG global estimé (y/c assurance obligatoire) de
          1,68 %. Coût total du crédit (y/c assurance) : 51 711,11 €. Conditions
          détaillées sur le site cogedim.com. Exemple communiqué, sans valeur
          contractuelle, établi sur la base de taux moyens du marché constatés
          au 4/04/2024 pour diverses situations comparables. Altarea Solution
          Financement est une société de courtage en crédit immobilier et en
          assurances. Siège social : 87 Rue de Richelieu 75002 Paris. SAS au
          capital de 10 000 € immatriculée au R.C.S Paris sous le N°504 638 784
          et au registre de l’Orias (www.orias.fr) sous le N°10053430.
          L’emprunteur dispose d’un délai légal de réflexion de 10 jours.
          Lorsque la vente est subordonnée à l’obtention d’un prêt et si
          celui-ci n’est pas obtenu, le vendeur doit rembourser les sommes
          versées par l’acquéreur. Le versement des mensualités, hors
          assurances, débutera à la date de livraison de l’appartement. Un
          crédit vous engage et doit être remboursé. Vérifiez vos capacités de
          remboursement avant de vous engager. Sous réserve d'acceptation de
          votre dossier par nos partenaires bancaires. Cogedim SAS, 87, rue de
          Richelieu 75002 Paris, RCS PARIS n° 054 500 814 - SIRET 05 450 081 400
          063. Conditions complètes consultables{" "}
          <a
            href="https://www.cogedim.net/animations/brochure/Cogedim_Access_Details_Simulation.pdf"
            target="_blank"
            class="ext"
            rel="noopener noreferrer nofollow"
          >
            ici.
          </a>
        </p>
      </Portal>
    </>
  );
}
