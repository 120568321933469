import CogedimAccessMonthlySearchPage from "~/components/CogedimAccess/CogedimAccessMonthlySearchPage";
import { Title } from "@solidjs/meta";
import { MetaTags } from "~/components/shared/MetaTags";

export default function CogedimAccessMonthlySearchEngine() {
  const metaDesc = {
    tag: "meta",
    attributes: {
      name: "description",
      content:
        "Avec la solution ACCESS, pour la première fois vous pouvez devenir propriétaire.",
    },
  };

  return (
    <>
      <Title>
        Programmes immobiliers neufs : Avec la solution ACCESS, pour la première
        fois vous pouvez devenir propriétaire. | Cogedim
      </Title>
      <MetaTags tags={[metaDesc]} />

      <CogedimAccessMonthlySearchPage />
    </>
  );
}
