import { useMediaOverlayContext } from "~/contexts/MediaOverlayContext";

import "./SeoText.css";

import IconPlayCircle from "~/img/icons/play_circle.svg";

export default function SeoTextIntro() {
  const [, { setMediaOverlay }] = useMediaOverlayContext();

  return (
    <>
      <div class="content-part block-seo" data-test="block-seo">
        <section class="acces-propriete">
          <h2>
            <img
              src="/images/nouveau-neuf/logo-access-purple.svg"
              alt="Access"
              height="34"
              width="162"
            />
            qu’est-ce que c’est ?
          </h2>
          <p class="center">
            <strong class="black">
              Une opportunité unique de devenir propriétaire pour la première
              fois, sans bouleverser votre budget.
            </strong>
            <br />
            La solution Access vous permet d’acquérir un logement neuf en
            réglant des mensualités proches d’un loyer. Et pour plus de
            sérénité, vous n’avez rien à payer avant d’emménager dans votre
            nouveau chez-vous
            <sup>(1)</sup>. Une solution adaptée et sécurisante vers la
            propriété.
          </p>
          <div class="button">
            <button
              type="button"
              onClick={() => {
                setMediaOverlay(
                  "embed",
                  '<iframe height="100%" width="100%" src="https://www.youtube.com/embed/CvZW7AbFssU?si=AIDqQ_ranLMU7Nou" allowfullscreen></iframe>',
                );
              }}
              class="btn"
              data-test="btn-video"
            >
              <i aria-hidden="true" class="cog-icon">
                <IconPlayCircle />
              </i>
              Voir la vidéo
            </button>
          </div>
        </section>
      </div>
    </>
  );
}
