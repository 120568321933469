import imgProxy from "~/utils/imgproxy";
import { createSignal, onCleanup, onMount } from "solid-js";
import { isServer } from "solid-js/web";

import "./FirstScreen.css";

import IconAccountBox from "~/img/icons/account_box.svg";

export default function FirstScreen() {
  const [anchorsShouldStack, setAnchorsShouldStack] = createSignal(false);

  onMount(() => {
    document.addEventListener("scroll", handleScroll);
  });

  onCleanup(() => {
    if (!isServer) {
      document.removeEventListener("scroll", handleScroll);
    }
  });

  const handleScroll = () => {
    if (window.scrollY > 500 - 100) {
      setAnchorsShouldStack(true);
    } else {
      setAnchorsShouldStack(false);
    }
  };

  return (
    <>
      <section class="full-visual" data-test="first-screen">
        <picture>
          <source
            media="(min-width: 1367px)"
            srcset={imgProxy(
              "/images/nouveau-neuf/access-visual-landing-desktop.jpg",
              "size:1920:1080/resizing_type:fill",
            )}
          />
          <source
            media="(min-width: 1025px) and (max-width: 1366px)"
            srcset={imgProxy(
              "/images/nouveau-neuf/access-visual-landing-desktop.jpg",
              "size:1366:768/resizing_type:fill",
            )}
          />
          <source
            media="(min-width: 768px) and (max-width: 1024px)"
            srcset={imgProxy(
              "/images/nouveau-neuf/access-visual-landing-desktop.jpg",
              "size:1024:576/resizing_type:fill",
            )}
          />
          <source
            media="(min-width: 376px)"
            srcset={imgProxy(
              "/images/nouveau-neuf/access-visual-landing.jpg",
              "size:768:432/resizing_type:fill",
            )}
          />
          <source
            media="(max-width: 375px)"
            srcset={imgProxy(
              "/images/nouveau-neuf/access-visual-landing.jpg",
              "size:533:300/resizing_type:fill",
            )}
          />
          <img
            fetchpriority="high"
            src={imgProxy(
              "/images/nouveau-neuf/access-visual-landing.jpg",
              `size:533:300/resizing_type:fill`,
            )}
            alt=""
            height="300"
            width="375"
            loading="lazy"
          />
        </picture>

        <div class="heading" data-test="header">
          <img
            src="/images/nouveau-neuf/logo-access.svg"
            alt="Access"
            height="50"
            width="240"
          />
          <h1>
            <strong>Avec ACCESS</strong>,<br />
            pour la première fois
            <br />
            vous pouvez devenir propriétaire.
          </h1>
          <a href="#accompagnement" class="btn">
            Je réalise mon projet immobilier
          </a>
        </div>

        <div
          class="anchors"
          data-test="anchors"
          classList={{ stacked: anchorsShouldStack() }}
        >
          <a href="#accompagnement" class="btn">
            <i aria-hidden="true" class="cog-icon">
              <IconAccountBox />
            </i>{" "}
            Être accompagné.e
          </a>
        </div>
      </section>
    </>
  );
}
